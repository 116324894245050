/* page */
.hidden {
	display: none;
}
body {
	margin: 0;
}
body, input, select, button, textarea {
	font-family: Helvetica, sans-serif;
	font-size: 14px;
}
.ui-widget {
	font-family: Helvetica, sans-serif;
}

/* History Table */
table.history {
	border-collapse: collapse;
	border: 1px solid black;
}
table.history caption {
	font-size:1.2em;
}
table.history th,table.history td {
	border: 1px solid black;
	white-space:nowrap;
}
table.history th {
	background-color:#5B5B5B;
	color:#FFFFFF;
	font-size:1.1em;
	padding-bottom:4px;
	padding-top:5px;
}
table.history tbody th {
	text-align:left;
}
table.history td {
	padding:3px 7px 2px;
	text-align:right;
}
table.history tr.even td {
	background-color:#EEEEEE;
	color:#000000;
}

table.history tr.odd td {
	background-color:white;
	color:#000000;
}

thead.rotate th{
	border: 0px;
	padding: 0px;
}
thead.rotate th > span > span {
	border: 1px solid black;
	background-color:#5B5B5B;
	color:#FFFFFF;
	white-space: nowrap;
}

/* standard icons */
.sticon {
	display: block;
	float: left;
	width: 16px;
	height: 16px;
	background: url('../img/standard.png') no-repeat 0% 0%;
}

.stheat {	background-position: 0px 0px;}
.stfan {	background-position: -16px 0px;}
.sttemp {	background-position: -32px 0px;}
.sthouse {	background-position: -48px 0px;}
.sthousex {	background-position: -64px 0px;}
.stalarm {	background-position: -80px 0px;}
.stalarmx {	background-position: -96px 0px;}
.stx {		background-position: -112px 0px;}
.stwarn {	background-position: -128px 0px;}
.stclock {	background-position: -144px 0px;}
.stsmile {	background-position: -160px 0px;}
.stsad {	background-position: -176px 0px;}
.stanemox {	background-position: -192px 0px;}
.stheatx {	background-position: -208px 0px;}
.sthandr {	background-position: -224px 0px; width: 32px;}
.sthandl {	background-position: -256px 0px; width: 32px;}
.stsnow {	background-position: -288px 0px;}
.sthyst {	background-position: -304px 0px;}
.stfull {	background-position: -320px 0px;}
.stempty {	background-position: -336px 0px;}
.stmoon {	background-position: -352px 0px;}
.stsun {	background-position: -368px 0px;}
.stbloklos {	background-position: -384px 0px;}
.stflame {	background-position: -400px 0px;}
.stlightbulb {	background-position: -416px 0px;}
.stmessage {	background-position: -432px 0px;}
.stanemo {	background-position: -448px 0px;}
.stflap {	background-position: -464px 0px;}
.stdrops {	background-position: -480px 0px;}
.stpower {	background-position: -496px 0px;}

/* Table */

table.tableCss {
	font-size:1.1em;
}

table.tableEventLog {
	font-size:small;
}

table.tableCss th {
	background-color:#008174;
	color:#FFFFFF;
	font-size:1.1em;
	padding-bottom:4px;
	padding-top:5px;
}

table.tableCss tbody th {
	text-align:left;
	white-space: nowrap;
}

table.tableCss td {
	padding:3px 7px 2px;
	text-align:right;
}

table.tableCss tr.odd  {
	background-color: #EEEEEE;
}

table.tableCss tr.even {
	background-color: white;
}

table.tableCss tr.selected {
	background-color: #758689;
	color:white;
	font-style:oblique;
}

.ui-widget-overlay
 {
	background:black;
 }

.ui-dialog-custom{
	background:#C0D6D3;
}

.ui-dialog-titlebar-custom{
	background:#138275;
	color:#FFFFFF;
}

.ui-dialog-titlebar-close-custom{
	visibility:hidden;
}

.ui-dialog-buttonpane-custom {
	background:#138275;
}

.disable-select {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}