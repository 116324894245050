
.card-title {
  font-weight: bold;
  font-size: 14px;
  color: #333333;
}

.card-subtitle {
  font-size: 11px;
  color: #666666;
}

.v-data-table::v-deep th {
  font-size: 10px !important;
}
.v-data-table::v-deep td {
  font-size: 10px !important;
}
