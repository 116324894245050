
.nav-drawer-combobox {
  .button {
    display: grid;
    width: 301px;
    grid-template-columns: 20px 35px 206px 40px;
    height: 45px;
    color: var(--v-grey30-base);

    &:hover {
      cursor: pointer;
    }

    span {
      a {
        color: var(--v-grey30-base);
      }

      a:hover {
        cursor: pointer;
        text-decoration: underline;
        color: var(--v-white-base);
      }
    }

    .icon {
      grid-column: 2;
      grid-row: 1;
      align-items: center;
      font-size: 25px;
    }

    .text {
      grid-column: 3;
      grid-row: 1;
      display: flex;
      align-items: center;
      color: var(--v-grey30-base);
      font-family: var(--v-font-base);
      font-size: 14px;
      font-weight: bold;
      line-height: 1;
    }

    .icon-warning {
      color: var(--v-warning-base);
      margin-left: 5px;
    }

    .icon-connected {
      color: var(--v-error-base);
      margin-left: 5px;
    }

    .arrow {
      grid-column: 4;
      grid-row: 1;
      display: flex;
      align-items: center;
      width: 30px;
      color: var(--v-grey30-base);

      .down {
        transition-duration: 0.4s;
        transition-property: transform;
      }

      .up {
        transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
        transition: 0.4s;
      }
    }
  }

  .content {
  }
}
