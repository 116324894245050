
.nav-drawer-static-node {
  width: 301px;
  display: grid;
  grid-template-columns: 30px 25px 206px 40px;
  padding: 5px 0 5px 0;

  span {
    a {
      color: var(--v-grey30-base);
    }

    a:hover {
      text-decoration: underline;
      color: var(--v-white-base);
    }
  }

  .icon {
    grid-column: 2;
    grid-row: 1;
    display: flex;
    align-items: center;
    color: var(--v-grey30-base);
    font-family: var(--v-font-base);
    font-size: 18px;
    font-weight: bolder;
    line-height: 1;
  }

  .text {
    grid-column: 3;
    grid-row: 1;
    display: flex;
    align-items: center;
    height: 20px;
    color: var(--v-grey30-base);
    font-family: var(--v-font-base);
    font-size: 14px;
    font-weight: normal;
    line-height: 1;
  }

  .notification {
    grid-column: 4;
    grid-row: 1;
    height: 20px;
    width: 30px;
    border-radius: 10px;
    color: var(--v-white-base);
    font-family: var(--v-font-base);
    font-size: 11px;
    font-weight: bolder;
    line-height: 1;
    text-align: center;
    padding: 5px 0 5px 0;
    background-color: var(--v-error-base);
  }

  .slot {
    grid-row: 2;
    padding-top: 5px;
  }

  .icon-warning {
    color: var(--v-warning-base);
    margin-left: 5px;
  }

  .icon-connected {
    color: var(--v-error-base);
    margin-left: 5px;
  }

  .icon-locked {
    color: var(--v-grey30-base);
    margin-left: 5px;
  }
}
