fieldset {
    display: inline;
    border: solid #e6e6e6 1px;
    border-bottom: solid #c3c3c3 5px;
}

fieldset legend {
    padding: 5px 8px 5px 8px;
    background-color: #FFFFFF;
    border: solid #e6e6e6 1px;
    font-weight: bold;
}

input.field {
    outline: none;
    text-align: left;
}

input.time_input {
    width: 110px;
}

select.field {
    padding: 1px 5px;
    outline: none;
}

input.field:not([type=checkbox]):not([type=radio]):not([type=file]) {
    vertical-align: middle;
    padding: 1px 5px;
}

input.field:not(.fake):not(.read-only):not(.non-edit):not(.large), select.field {
    border: 1px solid #e6e6e6;
    border-bottom: 2px solid #c3c3c3;
    text-align: right;
}

input.field[readOnly]:not(.fake):not(.read-only):not(.non-edit), select.readonly {
    background-color: #e6e6e6;
    border-bottom: 2px solid #c3c3c3;
}

select.field:not(.readonly) {
    border-right: 10px solid #c3c3c3;
}

.field.non-edit {
    background-color: #e6e6e6;
    border-bottom: 2px solid #c3c3c3;
}

.field.fake {
    background-color: transparent;
    border: 0 !important;
    margin: 0 !important;
    padding: 0 !important;
    text-align: center !important;
}

.field.read-only {
    background-color: transparent;
    border: 0 !important;
    margin: 0 !important;
    padding: 0 !important;
}

.field.small:not(select) {
    width: 48px !important;
    text-align: left;
}

select.field.small {
    width: 48px !important;
    text-align: left;
}

.field.large:not(select) {
    width: 128px !important;
    text-align: left;
    border: 1px solid #e6e6e6;
    border-bottom: 2px solid #c3c3c3;
}

select.field.large {
    width: 136px !important;
    text-align: left;
}

.field.changed {
    background-color: orange;
}

.field.SetDataFailed {
    background-color: red;
}

td:empty {
    height: 1em;
}