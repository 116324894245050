.screen >>> fieldset {
    padding: 25px;
}

.screen >>> select.field {
    height: 27px;
    width: 100px;
}

.screen >>> input.field:not([type=checkbox]):not([type=radio]):not([type=file]) {
    height: 27px;
    width: 100px;
}