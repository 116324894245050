
.nav-footer {
  background-color: var(--v-white-base);
  border-top: var(--v-grey20-base) 1px solid;

  .slide-group {
    width: 100vw;

    ::v-deep.v-slide-group__next,
    ::v-deep.v-slide-group__prev {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 35px;
      background-color: var(--v-white-base);
      color: var(--v-grey20-base);
    }

    div {
      height: 34px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0px 20px 0px 20px;
      background-color: var(--v-white-base);

      .text {
        padding-left: 5px;
      }

      .selected {
        border-top: var(--v-primary-base) 1px solid;
        cursor: pointer;
        color: var(--v-primary-base);
        background-color: var(--v-grey10-base);
      }
    }
  }

  .unlocked {
    &:hover {
      transition: 0.2s;
      border-top: var(--v-primary-base) 1px solid;
      cursor: pointer;
      color: var(--v-primary-base);
      background-color: var(--v-grey05-base);
    }

    .icon {
      font-weight: bolder;
      font-size: 16px;
      color: var(--v-primary-base);
    }
  }

  .locked {
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 20px 0px 20px;
    color: var(--v-grey60-base);

    .icon {
      font-weight: bolder;
      font-size: 16px;
      color: var(--v-grey60-base);
    }
  }
}
