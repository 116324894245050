
.level0 {
  background-color: var(--v-black-base);
}

.level1 {
  background-color: var(--v-level1-base);
}

.level2 {
  background-color: var(--v-level2-base);
}

.level3 {
  background-color: var(--v-level3-base);
}

.level4 {
  background-color: var(--v-level4-base);
}

.level5 {
  background-color: var(--v-level5-base);
}

.admin {
  padding: 5px 0 5px 0;
}
