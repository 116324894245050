
.nav-drawer {
  color: var(--v-grey30-base);
  border-left: 19px solid var(--v-primary-base);
  background-color: var(--v-black-base);
  border-image: linear-gradient(
      90deg,
      var(--v-primary-base),
      var(--v-black-base)
    )
    0 50%;
  ::-webkit-scrollbar {
    width: 0;
  }
}

.disable-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
