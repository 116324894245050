
.nav-drawer-footer-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 148px;
  height: 84px;
  cursor: pointer;
  transition: 0.2s;
  color: var(--v-grey30-base);

  .icon {
    font-size: 25px;
  }

  .text {
    font-family: var(--v-font-base);
    font-size: 14px;
    font-weight: bolder;
    line-height: 1;
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
}
