/*copied from fc2 (user agent stylesheet)*/
.custom-screen >>> fieldset {
    margin-inline-start: 2px;
    margin-inline-end: 2px;
    padding-block-start: 0.35em;
    padding-inline-start: 0.75em;
    padding-inline-end: 0.75em;
    padding-block-end: 0.625em;
    min-inline-size: min-content;
    border-image: initial;
}

.custom-screen >>> select.field {
    height: 26px;
    width: 93px;
}

.custom-screen >>> input.field:not([type=checkbox]):not([type=radio]):not([type=file]) {
    height: 22px;
    width: 85px;
}