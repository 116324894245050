
.nav-drawer-header {
  display: grid;
  width: 301px;
  height: 64px;
  grid-template-columns: 20px 35px 246px;
  color: var(--v-grey30-base);

  .icon {
    grid-column: 2;
    grid-row: 1;
    padding-top: 15px;
    font-size: 25px;
  }

  .name {
    grid-column: 3;
    grid-row: 1;
    padding-top: 18px;
    font-family: var(--v-font-base);
    font-size: 18px;
    font-weight: bolder;
  }

  .service {
    grid-column: 3;
    grid-row: 1;
    padding-top: 40px;
    font-family: var(--v-font-base);
    font-size: 12px;
    color: var(--v-warning-base);
  }
}
