
amplify-authenticator {
  --container-height: 0; /* default 100vh */
  --padding: 0;
  --box-shadow: 0;
  --border-radius: 0;
  --amplify-primary-color: var(--v-primary-base);
  --amplify-primary-tint: var(--v-primary-base);
  --amplify-primary-shade: var(--v-primary-base);
  height: 400px;
}

.logo {
  width: 200px;
  position: absolute;
  bottom: 0;
  left: 10px;
}
