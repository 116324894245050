
.slide-up-enter {
  transform: translateY(70px);
  opacity: 0;
}

.slide-up-leave-to {
  opacity: 0;
}

.slide-up-enter-active,
.slide-up-leave-active,
.slide-up-move {
  transition: all 0.5s ease;
}
