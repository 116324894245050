
amplify-authenticator {
  --container-height: 0; /* default 100vh */
  --padding: 20px;
  --box-shadow: 0;
  --border-radius: 0;
  --width: 325px;
  --amplify-primary-color: var(--v-primary-base);
  --amplify-primary-tint: var(--v-primary-base);
  --amplify-primary-shade: var(--v-primary-base);
}

.logo {
  width: 225px;
  position: absolute;
  top: 100px;
  left: 50px;
}
